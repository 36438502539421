import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b504828e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=b504828e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChartsHighlights: require('/opt/build/repo/components/charts-highlights.vue').default,ActionsPlanProgress: require('/opt/build/repo/components/actions-plan-progress.vue').default,ObjectiveAndLog: require('/opt/build/repo/components/objective-and-log.vue').default,MessagesAlert: require('/opt/build/repo/components/messages-alert.vue').default,NavBottom: require('/opt/build/repo/components/nav-bottom.vue').default,Quote: require('/opt/build/repo/components/quote.vue').default})

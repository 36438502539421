export default {
  data() {
    return {
      pwaStandaloneDeferredPrompt: undefined,
      pwaStandaloneWasInstalled: false,
    };
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', this.pwaStandaloneBeforeInstallPrompt);
    window.addEventListener('appinstalled', () => {
      this.pwaStandaloneWasInstalled = true;
    });
    window.addEventListener('DOMContentLoaded', () => {
      // let displayMode = 'browser tab';

      if (navigator.standalone) {
        // displayMode = 'standalone-ios';
        this.pwaStandaloneWasInstalled = true;
      }

      if (window.matchMedia('(display-mode: standalone)').matches) {
        // displayMode = 'standalone';
        this.pwaStandaloneWasInstalled = true;
      }
    });
  },
  methods: {
    pwaStandaloneShowInstallPromotion() {
      this.$bvToast.toast('Add to home screen', {
        title: `Install ${this.$store.state.app.name}`,
        variant: 'Info',
        solid: true,
      });
    },
    pwaStandaloneBeforeInstallPrompt(e) {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.pwaStandaloneDeferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.pwaStandaloneShowInstallPromotion();
    },
    pwaStandaloneInstall() {
      // Hide the app provided install promotion
      // hideMyInstallPromotion();
      // Show the install prompt
      this.pwaStandaloneDeferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.pwaStandaloneDeferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // eslint-disable-next-line no-console
          console.log('User accepted the install prompt');
        } else {
          // eslint-disable-next-line no-console
          console.log('User dismissed the install prompt');
        }
      });
    },
  },
};


import actionsPlanMixin from '../mixins/actionsPlan';
import dataMixin from '../mixins/data';
import pageMixin from '../mixins/page';
import pwaStandaloneMixin from '../mixins/pwaStandalone';
import uiMixin from '../mixins/ui';

import ActionsPlanProgress from '../components/actions-plan-progress';
import ChartsHighlights from '../components/charts-highlights';
import Quote from '../components/quote';
import MessagesAlert from '../components/messages-alert';
import ObjectiveAndLog from '../components/objective-and-log';

export default {
  name: 'IndexPage',
  components: {
    ActionsPlanProgress,
    ChartsHighlights,
    Quote,
    MessagesAlert,
    ObjectiveAndLog,
  },
  mixins: [
    actionsPlanMixin,
    dataMixin,
    pageMixin,
    pwaStandaloneMixin,
    uiMixin,
  ],
  head() {
    return {
      script: [
        {
          // src: 'https://greggman.github.io/webgl-lint/webgl-lint.js',
        }
      ],
    };
  },
  computed: {
    title() {
      let title = 'Do Something';

      if (this.$store.state.actionFocus.action && this.$store.state.actionFocus.isLocal) {
        title = `${this.$store.getters['actionFocus/counterDisplay']} ${this.$store.state.actionFocus.action.description}`;
      }

      return title;
    },
    messagesCount() {
      return this.$store.getters['ui/messages'].length;
    },
  },
};
